import { useAppSelector } from "@app/hooks"
import { ProgramManagementCalendar } from "@clients/calendarHubClient"
import { selectShowCalendarFeature } from "@features/login/featureFlagSelectors"
import { selectPrograms } from "@features/program/programSliceSelectors"
import MsCalendar from "@masterschool/ui-calendar"
import { Stack } from "@mui/material"
import { useMemo, useState } from "react"
import {
  buildMsCalendarGroupings,
  mapToMsCalendarProps,
} from "./calendarMappers"
import { useEventActions } from "./useEventActions"
import {
  useGetCalendarReferencedByEvents,
  useGetCalendars,
} from "./useGetCalendar"
import { selectLoggedInUser } from "@features/login/loginSelectors"

const d1 = new Date("2023-08-10T10:00:00.000Z")
const d2 = new Date("2027-08-20T10:00:00.000Z")

type DateRange = {
  start: Date
  end: Date
}

const CalendarContainer = () => {
  const isCalendarFeatureEnabled = useAppSelector(selectShowCalendarFeature)

  const calendarsForMenu = useGetCalendars()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateRange] = useState<DateRange>({ start: d1, end: d2 })
  if (!isCalendarFeatureEnabled) {
    return null
  }
  return (
    <CalendarWithEvent
      calendarsForMenu={calendarsForMenu}
      dateRange={dateRange}
    />
  )
}

const CalendarWithEvent = ({
  calendarsForMenu,
  dateRange,
}: {
  calendarsForMenu: ProgramManagementCalendar
  dateRange: DateRange
}) => {
  const allCalendarIdsInMenu = useMemo(
    () =>
      [
        ...calendarsForMenu.programSchoolCalendars,
        ...calendarsForMenu.programStaffCalendars,
      ].map((c) => c.id),
    [calendarsForMenu],
  )

  const { msEvents, onChange, onDelete } = useEventActions(
    allCalendarIdsInMenu,
    dateRange,
  )
  const programs = useAppSelector(selectPrograms)
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.clientId
  const calendarsReferencedByEvents = useGetCalendarReferencedByEvents(msEvents)
  const fullCalendarList = useMemo(
    () =>
      [
        ...calendarsForMenu.programSchoolCalendars,
        ...calendarsForMenu.programStaffCalendars,
        ...calendarsReferencedByEvents,
      ].map(mapToMsCalendarProps),
    [calendarsForMenu, calendarsReferencedByEvents],
  )

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      <Stack flex={1} overflow="auto">
        <MsCalendar
          events={msEvents}
          calendars={buildMsCalendarGroupings(calendarsForMenu, programs)}
          getCalendar={(calendarId: string) =>
            fullCalendarList.find((c) => c.id === calendarId)
          }
          onEventChange={onChange}
          supportCreateEventTypes={["live-session", "regular"]}
          userId={userId ?? ""}
          onDeleteEvent={onDelete}
          showDraftEvents
          canJoinLiveSessions={false}
        />
      </Stack>
    </Stack>
  )
}

export default CalendarContainer
