import {
  CalendarHubClient,
  CustomCalendarResponse,
  ProgramManagementCalendar,
} from "@clients/calendarHubClient"
import { MsEventProps } from "@masterschool/ui-calendar/dist/src/types"
import { useEffect, useMemo, useState } from "react"

export const useGetCalendars = () => {
  const [calendars, setCalendars] = useState<ProgramManagementCalendar>({
    programSchoolCalendars: [],
    programStaffCalendars: [],
    studentCalendars: [],
    allMasterschoolStaffCalendars: [],
  })
  useEffect(() => {
    CalendarHubClient.getProgramCalendars().then((res) => {
      setCalendars(res)
    })
  }, [])
  return calendars
}

export const useGetCalendarReferencedByEvents = (msEvents: MsEventProps[]) => {
  const participantCalendarIds = useMemo(
    () => msEvents.flatMap((e) => e.participantCalendarIds || []).msUnique(),
    [msEvents],
  )
  const [calendars, setCalendars] = useState<CustomCalendarResponse>({
    personalCalendars: [],
    schoolCalendars: [],
  })
  useEffect(() => {
    CalendarHubClient.getCalendarsById(participantCalendarIds).then((res) => {
      setCalendars(res)
    })
  }, [participantCalendarIds])

  const fullCalendarList = calendars
    ? [...calendars.personalCalendars, ...calendars.schoolCalendars]
    : []
  return fullCalendarList
}
