import {
  CreateMsEventDto,
  MsEventDto,
  MsOfferingCalendarDto,
  MsPrivateCalendarDto,
  ProgramManagementCalendar,
} from "@clients/calendarHubClient"
import { ProgramDto } from "@masterschool/course-builder-api"
import {
  CalendarGroupings,
  DoubleNestedCalendarGroupingSubgroup,
  MsCalendarPrivateCalendarProps,
  MsEventProps,
  MsOfferingCalendarProps,
  MuiColorPalette,
} from "@masterschool/ui-calendar/dist/src/types"
import { programDisplayName } from "../../programs/program/ui/program+ui"

/*
The file is still WIP,
 we don't have yet the full API, so some values are hardcoded here just to make the app work
*/

export const mapToMsEventDto = (event: MsEventProps): CreateMsEventDto => {
  if (event.type !== "regular" && event.type !== "live-session") {
    console.error(`Unsupported event type: ${event.type}`)
    throw new Error(`Unsupported event type: ${event.type}`)
  }
  return {
    title: event.title,
    description: event.description || "",
    start: event.start,
    end: event.end,
    presentAsAllDay: event.allDay || false,
    type: event.type,
    participants: event.participantCalendarIds || [],
    rrule: event.recurringRule || undefined,
    rruleUntil: event.recurringUntil || undefined,
    rruleTzid: event.recurringRuleTzid || undefined,
    hasVc: event.vc?.hasVc || false,
    instructorCalendarId:
      event.type === "live-session" ? event.instructorCalendarId : undefined,
    calendarId: event.calendarId,
  }
}

export const mapToMsEventProps = (event: MsEventDto): MsEventProps => {
  return {
    id: event.id,
    title: event.title,
    description: event.description,
    start: new Date(event.start),
    end: new Date(event.end),
    type: event.type,
    allDay: event.presentAsAllDay,
    recurringId: event.recurringGroupID,
    organizerClientId: event.organizerClientId,
    recurringRule: event.rrule || "",
    recurringUntil: event.rruleUntil || undefined,
    recurringRuleTzid: event.rruleTzid || undefined,
    participantCalendarIds: event.participants,
    calendarId: event.calendarId,
    color: event.color,
    vc: event.hasVc ? { hasVc: event.hasVc, url: event.vcUrl } : undefined,
    instructorCalendarId: event.instructorCalendarId,
  } as MsEventProps // TO DO check those typescript errors
}

export const buildMsCalendarGroupings = (
  calendars: ProgramManagementCalendar,
  programs: ProgramDto[],
): CalendarGroupings => {
  const programToCalendars = calendars.programSchoolCalendars.reduce(
    (acc, calendar) => {
      const program = programs.find(
        (p) => p.id === calendar.offeringDetails.programId,
      )
      if (!program) {
        return acc
      }
      if (!acc[program.id]) {
        acc[program.id] = { title: programDisplayName(program), calendars: [] }
      }
      acc[program.id].calendars.push(mapToMsCalendarSchoolProps(calendar))
      return acc
    },
    {} as Record<string, DoubleNestedCalendarGroupingSubgroup>,
  )

  return [
    {
      type: "double-nested",
      groupingsType: "classes",
      groupingsAction: "all",
      title: "Classes",
      subGroups: Object.values(programToCalendars),
    },
    {
      title: "Program Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "all",
      calendars: calendars.programStaffCalendars.map((c) =>
        mapToMsCalendarStaffProps(c),
      ),
    },
    {
      title: "Students",
      type: "single",
      groupingsType: "students",
      groupingsAction: "invite",
      calendars: calendars.studentCalendars.map((c) =>
        mapToMsCalendarStaffProps(c),
      ),
    },
    {
      title: "Masterschool Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "invite",
      calendars: calendars.allMasterschoolStaffCalendars.map((c) =>
        mapToMsCalendarStaffProps(c),
      ),
    },
  ]
}

const mapToMsCalendarSchoolProps = (
  calendar: MsOfferingCalendarDto,
): MsOfferingCalendarProps => {
  return {
    id: calendar.id,
    title: calendar.offeringDetails.name,
    color: calendar.color as MuiColorPalette, //to do check this
    type: "offering",
    offeringClientId: calendar.offeringDetails.offeringClientId,
  }
}

const mapToMsCalendarStaffProps = (
  calendar: MsPrivateCalendarDto,
): MsCalendarPrivateCalendarProps => {
  return {
    id: calendar.id,
    title: calendar.name,
    color: calendar.color as MuiColorPalette, //to do check this
    type: "private",
    userClientId: calendar.userClientId,
    roles: calendar.roles || [],
  }
}

export const mapToMsCalendarProps = (
  calendar: MsPrivateCalendarDto | MsOfferingCalendarDto,
): MsCalendarPrivateCalendarProps | MsOfferingCalendarProps => {
  if ("offeringDetails" in calendar) {
    return mapToMsCalendarSchoolProps(calendar)
  } else {
    return mapToMsCalendarStaffProps(calendar)
  }
}
