import GenericTable from "@cmp/genericTable"
import { SearchComponent } from "@cmp/searchComponent"
import { Box, Button, Link, Stack, SvgIcon, Typography } from "@mui/material"
import { useCallback, useEffect } from "react"
import {
  generateLinkForUser,
  renderRoles,
} from "../account-management/accountManagement"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import appIcons from "@utils/appIcons"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  selectFetchingState,
  selectUserSearchValue,
  selectUsersTabData,
} from "@features/users-tab/usersTabSelectors"
import {
  searchUsers,
  setUserSearchValue,
} from "@features/users-tab/usersTabSlice"
import { selectPrograms } from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { programDisplayName } from "../programs/program/ui/program+ui"
import roleDisplayNames from "../account-management/roleDisplayName"

export const UsersTab = () => {
  const tableData = useAppSelector(selectUsersTabData)
  const fetchingStatus = useAppSelector(selectFetchingState)
  const dispatch = useAppDispatch()
  const searchValue = useAppSelector(selectUserSearchValue)
  const programs = useAppSelector(selectPrograms)

  const onSearchClicked = useCallback(() => {
    dispatch(searchUsers())
  }, [dispatch])

  const onSearchTextChange = useCallback(
    (text: string) => {
      dispatch(setUserSearchValue(text))
    },
    [dispatch],
  )

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onSearchClicked()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [onSearchClicked])

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1240px",
        marginX: "auto",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pt: "80px",
          mb: "16px",
          gap: "16px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Users</Typography>
          {fetchingStatus === "fetching" && (
            <LoadingComponent loadedComponents="users" />
          )}
        </Stack>
        <Stack display="flex" gap="16px" width="100%">
          <Stack direction="row" gap={2}>
            <SearchComponent
              searchText={searchValue}
              searchPlaceholder="Enter user email or name (min 4 chars., partial is ok)"
              onSearchTextChange={onSearchTextChange}
              inputFilters={[]}
              onInputFiltersChange={() => {}}
              debounce={false}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "fit-content",
                flexShrink: 0,
              }}
              size="medium"
              onClick={onSearchClicked}
              disabled={searchValue.trim().length < 4}
            >
              Search
            </Button>
          </Stack>
          {tableData.length > 1 && (
            <Stack
              sx={{
                padding: 2,
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: 1,
                backgroundColor: "warning.light",
              }}
            >
              <Typography variant="body2_sb">
                Note: class and staff results are fetched for first row only.
              </Typography>
            </Stack>
          )}
          {tableData.length === 10 && (
            <Stack
              sx={{
                padding: 2,
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: 1,
                backgroundColor: "warning.light",
              }}
            >
              <Typography variant="body2_sb">
                Note: search is limited to 10 results for performance reasons.
              </Typography>
            </Stack>
          )}
          <GenericTable
            data={tableData}
            keys={[
              "firstName",
              "lastName",
              "email",
              "roles",
              "classData",
              "staffRoles",
              "clientId",
            ]}
            sx={{
              width: "100%",
              overflow: "auto",
            }}
            renderHeader={{
              classData: () => "Current Class",
              clientId: () => undefined,
            }}
            render={{
              roles: renderRoles,
              classData: RenderClass,
              staffRoles: (roles) =>
                roles ? (
                  <RenderStaffRoles roles={roles} programs={programs} />
                ) : null,
              clientId: (id, user) => (
                <ActionsMenu userId={id} email={user.email} />
              ),
            }}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

const ActionsMenu = (props: { userId: string; email: string }) => {
  // const dispatch = useAppDispatch()

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        // {
        //   type: "menu-item",
        //   props: {
        //     key: "edit-role",
        //     children: "Edit roles",
        //     onClick: () => {
        //       dispatch(editRolesRequested(props))
        //     },
        //   },
        // },
        // {
        //   type: "menu-item",
        //   props: {
        //     key: "edit-user",
        //     children: "Edit user details",
        //     onClick: () => {
        //       dispatch(editUserRequested(props))
        //     },
        //   },
        // },
        // {
        //   type: "menu-item",
        //   props: {
        //     key: "feature-flags",
        //     children: "Edit feature flags",
        //     onClick: () => {
        //       dispatch(editFeatureFlagsPopupOpened(props))
        //     },
        //   },
        // },
        {
          type: "menu-item",
          props: {
            key: "impersonate",
            children: "Copy impersonation link",
            onClick: () => generateLinkForUser(props.email),
          },
        },
      ]}
    />
  )
}

const RenderClass = (classData: any | null) => {
  if (!classData) return null
  const programId = classData.programId
  const classId = classData.clientId
  const href = `/program/${programId}/${classId}`
  return (
    <Link href={href} target="_blank" variant="body1">
      {classData.marketingDescription}
    </Link>
  )
}

const RenderStaffRoles = (props: {
  roles: { programId: string; roles: string[] }[]
  programs: ProgramDto[]
}) => {
  const { roles, programs } = props
  const ProgramRole = (props: { programId: string; roles: string[] }) => {
    const { programId, roles } = props
    const program = programs.find((p) => p.id === programId)
    return (
      <Stack>
        <Typography variant="body2" component="span">
          <Typography variant="body2_sb">Program: </Typography>
          <Link href={"/program/" + programId} target="_blank">{`${
            program ? programDisplayName(program) : "unknown"
          }\n`}</Link>
        </Typography>
        <Typography variant="body2" component="span">
          <Typography variant="body2_sb">Roles: </Typography>
          {` ${roles.map((r) => roleDisplayNames(r)).join(", ")}`}
        </Typography>
      </Stack>
    )
  }
  return (
    <Stack gap={1}>
      {roles.map((r, i) => (
        <div key={r.programId}>
          {i > 0 && <hr />}
          <ProgramRole {...r} />
        </div>
      ))}
    </Stack>
  )
}
