import { useAppDispatch, useAppSelector } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import GenericTable from "@cmp/genericTable"
import { ErrorComponent } from "@cmp/loaders/errorComponent"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import {
  editFeatureFlagsPopupOpened,
  editRolesRequested,
  editUserRequested,
  fetchUsersWithRoles,
  offboardStaffRequested,
  onboardStaffRequested,
  setSearch,
} from "@features/account-management/accountManagementSlice"
import { ALL_ROLES, Role } from "@features/login/loginSlice"
import {
  Box,
  Button,
  Chip,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import appTheme from "../theme/appTheme"
import EditRolesDialog from "./editRolesDialog"
import OffboardStaffDialog from "./offboardStaffDialog"
import OnboardStaffDialog from "./onboard-staff-dialog/onboardStaffDialog"
import roleDisplayNames from "./roleDisplayName"
import EditEmployeeDetailsDialog from "./editEmployeeDetailsDialog"
import EditUserFeatureFlagsDialog from "./editUserFeatureFlagsDialog"
import { UsersClient } from "@clients/usersClient"
import { store } from "@app/store"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { SearchComponent } from "@cmp/searchComponent"
import { FilterName, SearchAdvanceFilter } from "@features/search/searchSlice"

function AccountManagement() {
  const accounts = useAppSelector((state) => state.accountManagement.accounts)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (accounts.status === "idle") {
      dispatch(fetchUsersWithRoles())
    }
  }, [accounts.status, dispatch])

  const isLoading = accounts.status === "pending" || accounts.status === "idle"
  const isError = accounts.status === "error"

  const search = useAppSelector((state) => state.accountManagement.search)
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([
    Role.MasterschoolEmployee,
  ])
  const inputFilters: SearchAdvanceFilter[] = [
    {
      filterName: FilterName.Role,
      selectedValues: selectedRoles,
      optionalValues: ALL_ROLES,
    },
  ]
  const onFiltersChange = (filters: SearchAdvanceFilter[]) => {
    setSelectedRoles(filters[0].selectedValues as Role[])
  }

  const users = accounts.status !== "success" ? [] : accounts.data
  const filteredUsers = users
    .filter(
      (user) =>
        `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          search.toLowerCase(),
        ) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.roles.some((role) =>
          role.toLowerCase().includes(search.toLowerCase()),
        ),
    )
    .filter(
      (user) =>
        selectedRoles.length === 0 ||
        user.roles.some((role) => selectedRoles.includes(role as Role)),
    )
  const displayedUsersNumberText =
    search.length > 0
      ? `(${filteredUsers.length}/${users.length})`
      : `(${users.length})`

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <OnboardStaffDialog />
      <OffboardStaffDialog />
      <EditRolesDialog />
      <EditEmployeeDetailsDialog />
      <EditUserFeatureFlagsDialog />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              All programs staff{" "}
              <span
                style={{
                  color: appTheme.palette.text.disabled,
                }}
              >
                {displayedUsersNumberText}
              </span>
            </Typography>
            {isLoading && (
              <LoadingComponent loadedComponents="employees & staff" />
            )}
            {isError && <ErrorComponent loadedComponents="employees & staff" />}
          </Box>
          <Box display="flex" gap="16px" width="100%">
            <SearchComponent
              searchText={search}
              onSearchTextChange={(text) => dispatch(setSearch(text))}
              inputFilters={inputFilters}
              onInputFiltersChange={onFiltersChange}
              getOptionLabel={(role) => roleDisplayNames(role as Role)}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "fit-content",
                flexShrink: 0,
              }}
              size="medium"
              onClick={() => {
                dispatch(onboardStaffRequested())
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.plus}
                  sx={{
                    stroke: appTheme.palette.primary.contrastText,
                    fill: "inherit",
                    width: "20px",
                    height: "20px",
                  }}
                  inheritViewBox
                />
              }
            >
              Onboard Staff
            </Button>
          </Box>
          <GenericTable
            data={filteredUsers}
            keys={["firstName", "lastName", "email", "roles", "slackId", "id"]}
            sx={{
              width: "100%",
              overflow: "auto",
            }}
            renderHeader={{
              id: () => undefined,
              slackId: () => <Typography variant="body1_sb">Slack</Typography>,
            }}
            render={{
              roles: renderRoles,
              id: (id, user) => <ActionsMenu userId={id} email={user.email} />,
              slackId: (slackId) => <SlackIdCell slackId={slackId} />,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export function renderRoles(roles: string[]) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "4px",
        maxWidth: "200px",
      }}
    >
      {roles.map((role: string) => (
        <RoleChip key={role} role={role as Role} />
      ))}
    </Box>
  )
}

export const SlackIdCell = (props: { slackId: string | undefined }) => {
  const { slackId } = props
  if (!slackId) {
    return null
  }
  const link =
    "https://masterschool-campus.slack.com/app_redirect?channel=" + slackId
  return (
    <Tooltip title="Send message" arrow placement="bottom">
      <IconButton href={link} target="_blank">
        <SvgIcon
          component={appIcons.slackIcon}
          inheritViewBox
          sx={{ width: "20px", height: "20px" }}
        />
      </IconButton>
    </Tooltip>
  )
}

function RoleChip(props: { role: Role }) {
  return (
    <Chip
      label={roleDisplayNames(props.role)}
      variant="outlined"
      size="small"
      sx={{
        width: "fit-content",
      }}
    />
  )
}

const ActionsMenu = (props: { userId: string; email: string }) => {
  const dispatch = useAppDispatch()

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "edit-role",
            children: "Edit roles",
            onClick: () => {
              dispatch(editRolesRequested(props))
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "offboard",
            children: "Offboard",
            onClick: () => {
              dispatch(offboardStaffRequested(props))
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "edit-user",
            children: "Edit user details",
            onClick: () => {
              dispatch(editUserRequested(props))
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "feature-flags",
            children: "Edit feature flags",
            onClick: () => {
              dispatch(editFeatureFlagsPopupOpened(props))
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "impersonate",
            children: "Copy impersonation link",
            onClick: () => generateLinkForUser(props.email),
          },
        },
      ]}
    />
  )
}

export default AccountManagement

export async function generateLinkForUser(email: string) {
  if (!email) {
    return
  }
  await UsersClient.generateImpersonationLink(email)
    .then((link) => {
      navigator.clipboard.writeText(link)
      store.dispatch(
        showSuccessSnackbar("Link copied. Open the URL in incognito mode."),
      )
    })
    .catch(() => {
      store.dispatch(
        showErrorSnackbar(
          "Failed to copy impersonation link, try again later.",
        ),
      )
      return undefined
    })
}
